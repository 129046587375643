<template>
    <div class="ingredient-configuration">
        <p class="gram-title">{{ $t('production.configuration.title') }}</p>
        <div class="ingredient">
            <div class="ingredients" v-for="product in tempProducts" :key="product.id">
                <button class="toggle-switch" @click="toggleTempProduct(product.id)" :class="{ active: product.status }">
                    <div :class="{ slider: true, active: product.status }"></div>
                </button>
                <div class="name">{{ product.name }}</div>
                <select v-model="product.active_container" @change="updateActiveContainer(product.id, product.active_container)">
                    <option v-for="container in product.containers" :key="container.id" :value="container.id">
                        {{ container.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="btnExit">
            <button class="btn cancel-changes" @click="cancelChanges()">{{ $t('production.configuration.cancel') }}</button>
            <button class="btn save-changes" @click="saveChanges()">{{ $t('production.configuration.saveChanges') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductionConfiguration',
    data() {
        return {
            tempProducts: {}
        }
    },
    created() {
        this.loadProducts();
    },
    methods: {
        loadProducts() {
            this.tempProducts = JSON.parse(JSON.stringify(this.$store.getters['production/getProducts']));
            console.log('tempProducts', JSON.parse(JSON.stringify(this.$store.getters['production/getProducts'])))
        },
        cancelChanges() {
            this.loadProducts();
            this.$store.commit('production/setConfiguration', false)
        },
        saveChanges() {
            this.$store.commit('production/setProducts', this.tempProducts);
            this.$store.commit('production/setConfiguration', false);
        },
        toggleTempProduct(productId) {
            if (this.tempProducts[productId]) {
                this.tempProducts[productId].status = !this.tempProducts[productId].status;
            }
        },
        updateActiveContainer(productId, activeContainerId) {
            if (this.tempProducts[productId]) {
                this.tempProducts[productId].active_container = activeContainerId;
            }
        }
    }
}
</script>
<style lang="scss">
.ingredient-configuration {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.gram-title {
    font-size: 32px;
    padding: 20px 20px 20px 20px;
}
.ingredient {
    height: 70vh;
    margin-left: 20px;
    margin-top: 20px;

    .ingredients {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start; 
        position: relative; 
        margin: 20px 20px 0px 0px;

        .toggle-switch {
            width: 40px;
            height: 24px;
            background-color: $color-selector2;
            border-radius: 18px;
            position: relative;
            transition: background-color 0.3s;
            border: none;
            padding: 0;
            margin-right: 5px;

            &.active {
                background-color: $color-primary-700;
            }

            .slider {
                width: 18px;
                height: 18px;
                background-color: $color-selector;
                border-radius: 50%;
                position: absolute;
                top: 3px;
                left: 4px;
                transition: left 0.3s;

                &.active {
                left: 18px;
                }
            }
        }

        select {
            position: absolute; 
            left: 50%; 
            transform: translateX(-50%); 
            width: 250px; 
        }
    }
}
.btnExit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    .btn {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        margin-right: 10px;
        text-align: center;
        width: 200px;
    }
    .cancel-changes {
        font-family: $text-medium;
        color: $main-s10;
        background-color: $neutro;
        margin: 6px;

    }
    .save-changes {
        font-family: $text-bold;
        color: #fff;
        background-color: $main; 
    }
}
</style>